import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/Navbar";

export default function PrivacyPolicy(props: any) {
  return (
    <div>
      <Navbar isLoggedIn={false}></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="mt-5">Privacy Policy</h1>
                  <p>
                    Last updated: <strong>September 2024</strong>
                  </p>

                  <p>
                    Welcome to StockOverflow. This Privacy Policy explains how
                    we collect, use, and safeguard your information when you use
                    our website.
                  </p>

                  <h2 className="mt-4">1. Information We Collect</h2>
                  <p>
                    We collect personal information such as your name, email
                    address, and preferences when you sign up for our service.
                  </p>

                  <h2 className="mt-4">2. How We Use Your Information</h2>
                  <ul>
                    <li>
                      To provide you with notifications on bulk and block deals.
                    </li>
                    <li>
                      To customize your experience and send personalized alerts.
                    </li>
                    <li>To improve our website and services.</li>
                    <li>
                      To send you promotional information and updates, where you
                      have opted in.
                    </li>
                  </ul>

                  <h2 className="mt-4">3. Data Security</h2>
                  <p>
                    We use industry-standard encryption and security measures to
                    protect your information. However, please be aware that no
                    method of transmission over the Internet is 100% secure.
                  </p>

                  <h2 className="mt-4">4. Third-Party Services</h2>
                  <p>
                    We may use third-party services such as analytics tools and
                    payment processors. These services may have access to
                    certain personal information as required for their
                    operations.
                  </p>

                  <h2 className="mt-4">5. Your Rights</h2>
                  <p>
                    You have the right to access, correct, or delete your
                    personal information. To exercise these rights, please
                    contact us at{" "}
                    <a href="mailto:contact.stockoverflow@gmail.com">
                      contact.stockoverflow@gmail.com
                    </a>
                    .
                  </p>

                  <h2 className="mt-4">6. Updates to This Policy</h2>
                  <p>
                    We may update this policy periodically. We will notify you
                    of any significant changes via email or through the website.
                  </p>

                  <h2 className="mt-4">7. Contact Us</h2>
                  <p>
                    If you have any questions or concerns about our Privacy
                    Policy, feel free to reach out at{" "}
                    <a href="mailto:contact.stockoverflow@gmail.com">
                      contact.stockoverflow@gmail.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
